import React from 'react'
import { Box, withStyles, Typography } from '@material-ui/core'
import T from 'prop-types'
import { RichText } from 'gatsby-components'

function HeadedSubSection({ body, classes, title }) {
  return (
    <Box className={classes.layoutWrapper} component="div">
      <Typography className={classes.heading} component="h3">
        {title}
      </Typography>
      <div className={classes.body}>
        <RichText value={body} />
      </div>
    </Box>
  )
}

HeadedSubSection.propTypes = {
  classes: T.object.isRequired,
}

const styles = theme => ({
  layoutWrapper: {
    display: 'block',
    marginBottom: theme.spacing(3),
    '&:last-of-type': {
      marginBottom: 0,
    },
    '@media print': {
      marginBottom: 0,
    },
  },
  heading: {
    fontSize: '12px',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',

    '@media print': {
      fontSize: '14px',
      fontWeight: 'bold',
      display: 'inline-block',
      width: '100%',
    },
  },
  body: {
    '& > * > *': {
      margin: theme.spacing(0),
    },
    '@media print': {
      padding: '0 0 35px 0',
    },
  },
})

export default withStyles(styles)(HeadedSubSection)
