import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'

import { getFileUri } from '../../../utils/storage'

const AnswerImage = ({ file, classes }) => {
  const [imageUrl, setImageUrl] = useState(null)
  const asyncUseEffect = async () => {
    setImageUrl(await getFileUri(file.s3_key))
  }
  useEffect(() => {
    if (file && file.s3_key) {
      asyncUseEffect()
    }
  }, [file])

  return <img className={classes.root} src={imageUrl} alt="" />
}

const styles = theme => ({
  root: {
    maxWidth: '100%',
    maxHeight: '300px',
  },
})

export default withStyles(styles)(AnswerImage)
