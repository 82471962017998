import React from 'react'
import { Box, withStyles } from '@material-ui/core'
import T from 'prop-types'

import { RichText } from 'gatsby-components'
import rowPadding from '../row-padding'
import AnswerImage from './image'

const AnswerSection = ({ answer, isImage, classes, key, prefix, title }) => (
  <Box
    className={classes.answerSectionWrapper}
    component="div"
    key={`${prefix}-${key}`}
  >
    <span className={classes.prefix}>{prefix}</span>
    <span className={classes.title}>{title}</span>
    <span className={classes.body}>
      {isImage ? <AnswerImage file={answer} /> : <RichText value={answer} />}
    </span>
  </Box>
)

AnswerSection.propTypes = {
  answer: T.string, // Answer text
  classes: T.object, // MUI
  key: T.string, // Key for React
  prefix: T.string, // Prefix to title
  title: T.string, // Title for the answer section
  isImage: T.bool, // If field is type image
}

const styles = theme => ({
  answerSectionWrapper: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    paddingBottom: theme.spacing(rowPadding.paddingBottom),
    paddingTop: theme.spacing(rowPadding.paddingTop),
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '@media print': {
      display: 'block',
      breakInside: 'avoid',
    },
  },
  prefix: {
    flexBasis: '4%',
    paddingLeft: theme.spacing(4),
  },
  title: {
    flexBasis: '26%',
    flexGrow: 1,
    fontSize: '12px',
    fontWeight: '700',
    paddingLeft: theme.spacing(rowPadding.paddingLeft),
    paddingRight: theme.spacing(2),
    paddingTop: '1px',
    textTransform: 'uppercase',
  },
  body: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '70%',
    paddingRight: theme.spacing(rowPadding.paddingRight),
    whiteSpace: 'pre-wrap',
    '& > * > *': {
      margin: theme.spacing(0),
    },

    '@media print': {
      display: 'block',

      '& p': {
        display: 'block',
        breakInside: 'avoid',
      },
    },
  },
})

export default withStyles(styles)(AnswerSection)
